import React from 'react';
import PropTypes from 'prop-types';

import './FormInput.scss';

const FormInput = ({ type, name, placeholder, className, isRequired, value, onBlur, onChange }) => {
    const isTextarea = type === 'textarea' ? true : false;

    return (
        <>
            {isTextarea ?
                (<label htmlFor={name} className="textarea__label">
                    <textarea id={name} type={type} name={name} value={value} className={`input textarea ${className}`} required={isRequired} onBlur={onBlur} onChange={onChange} />
                    <span className="input__placeholder">{placeholder}</span>
                </label>)
                :
                (<label htmlFor={name} className="input__label">
                    <input id={name} type={type} name={name} className={`input text ${className}`} required={isRequired} value={value} onBlur={onBlur} onChange={onChange} />
                    <span className="input__placeholder">{placeholder}</span>
                </label>)
            }
        </>
    );
};

FormInput.propTypes = {
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    isRequired: PropTypes.bool,
}

FormInput.defaultProps = {
    className: '',
    isRequired: false
}

export default FormInput;