import React from 'react';
import Layout from '../components/layout';
import ContactForm from '../components/sections/ContactForm/ContactForm';

const Contact = () => (
    <Layout pageTitle="Contact me">
        <ContactForm />
    </Layout>
);

export default Contact;