import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import './Modal.scss';

const Modal = ({ children, toggleModal }, props) => {
    const modalRef = useRef(null);

    const useModalRef = ref => {
        useEffect(() => {
            const handleClickOutside = e => {
                if (ref.current && !ref.current.contains(e.target)) {
                    toggleModal();
                }
            };

            document.addEventListener('mousedown', e => handleClickOutside(e));
            document.addEventListener('touchdown', e => handleClickOutside(e));
            return () => {
                document.removeEventListener('mousedown', e => handleClickOutside(e));
                document.removeEventListener('touchdown', e => handleClickOutside(e));
            };
        });
    };

    useModalRef(modalRef);

    return (
        <div className="modal" ref={modalRef} {...props}>
            {children}
        </div>
    )
}

Modal.propTypes = {
    children: PropTypes.node.isRequired,
    toggleModal: PropTypes.func.isRequired,
}

export default Modal;