import React, { useState, useRef } from 'react';
import Recaptcha from 'react-recaptcha';
import cx from 'classnames';
import emailjs from 'emailjs-com';
import ButtonIcon from '../../ButtonIcon/ButtonIcon';
import Modal from '../../Modal/Modal';
import SendIcon from '../../../assets/icons/send.svg';
import CloseIcon from '../../../assets/icons/close.svg';
import LoaderIcon from '../../../assets/icons/loader.svg';
import './ContactForm.scss';
import FormInput from '../../FormInput/FormInput';
import { Helmet } from 'react-helmet';

const ContactForm = () => {

    const responses = {
        good: {
            heading: {
                text: 'Your email is sent!',
                class: 'contactWrapper__modal--heading'
            },
            message: {
                text: 'I\'ll reply you as soon as possible!',
                type: 'success',
            },
            waterSign: {
                text: 'SUCCESS!',
                class: 'contactWrapper__modal--waterSign'
            },
        },
        bad: {
            heading: {
                text: 'We were unable to send your message',
                class: 'contactWrapper__modal--heading contactWrapper__modal--headingErr'
            },
            message: {
                text: 'Something went wrong, please try again later.. or send me an email: ',
                type: 'error',
            },
            waterSign: {
                text: 'FAILURE!',
                class: 'contactWrapper__modal--waterSign contactWrapper__modal--waterSignErr'
            },
        },
    }

    const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*)@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]+)\])/;

    const siteKey = process.env.GATSBY_CAPTCHA_SITE_KEY;

    const captchaRef = useRef(null);

    const [name, setName] = useState('');
    const [nameError, setNameError] = useState(false);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [message, setMessage] = useState('');
    const [messageError, setMessageError] = useState(false);
    const [confirmedCaptcha, setConfirmedCaptcha] = useState(false);
    const [captchaError, setCaptchaError] = useState(false);
    const [emailResponses, setEmailResponses] = useState();

    const verifyCaptcha = () => {
        setConfirmedCaptcha(true);
        setCaptchaError(false);
    };

    const [isModalOpened, setIsModalOpened] = useState(false);
    const [loading, setLoading] = useState(false);

    const toggleModal = () => setIsModalOpened(!isModalOpened);

    const handleUserInput = e => {
        const value = e.target.value;
        const type = e.target.name;

        switch (type) {
            case 'name':
                setName(value);
                setNameError(false);
                if (value.trim() === '') return setNameError(true);
                break;
            case 'email':
                setEmail(value);
                setEmailError(false);
                if (e.target.className.includes('input__invalid')) {
                    const matchEmail = value.match(emailRegex) ? true : false;
                    if (matchEmail === false) return setEmailError(true);
                    return setEmailError(false);
                }
                if (value === '') return setEmailError(true);
                break;
            case 'message':
                setMessage(value);
                setMessageError(false);
                if (value.trim() === '') return setMessageError(true);
                break;
            default: break;
        }
    }

    const resetForm = () => {
        setName('');
        setEmail('');
        setMessage('');
        setConfirmedCaptcha(false);
        setCaptchaError(false);
        captchaRef.current.reset();
    }

    const validateForm = () => {
        const fields = ['name', 'email', 'message'];

        const matchEmail = email.match(emailRegex) ? true : false;

        fields.forEach(field => {
            switch (field) {
                case 'name':
                    setNameError(false);
                    if (name.trim() === '') return setNameError(true);
                    break;
                case 'email':
                    setEmailError(false);
                    if (matchEmail === false) return setEmailError(true);
                    break;
                case 'message':
                    setMessageError(false);
                    if (message.trim() === '') return setMessageError(true);
                    break;
                default: break;
            }
        });

        if (confirmedCaptcha === false) setCaptchaError(true);

        if (name.trim() === '' || email === '' || message.trim() === '' || matchEmail === false || confirmedCaptcha === false) return false;

        return true;

    }

    const sendEmail = async () => {
        const template = process.env.GATSBY_EMAILJS_TEMPLATEID;
        const user = process.env.GATSBY_EMAILJS_USERID;

        toggleModal();
        setLoading(true);

        try {
            await emailjs.send(
                "default_service",
                template,
                {
                    email,
                    message,
                    name
                },
                user
            );
            await setEmailResponses(responses.good);
            await setLoading(false);
            resetForm();
        } catch ({ text }) {
            setEmailResponses(responses.bad);
            setLoading(false);
            throw new Error(text);
        }
    }

    const submitForm = () => {
        if (validateForm() === false) return;
        sendEmail();
    }

    return (
        <section className="contactWrapper">
            <header className="contactWrapper__header">
                <h2 className="contactWrapper__header--heading">Let's keep in touch...</h2>
                <p className="contactWrapper__header--paragraph">Don't like contact forms? Send me an <a href="mailto:kontakt@piotrkawula.pl" className="contactWrapper__header--paragraphLink">e-mail</a>!</p>
            </header>
            <form className="contactWrapper__form">
                <FormInput className={cx({ 'input__invalid': nameError })} type="text" name="name" placeholder="your name" isRequired={true} value={name} onBlur={e => handleUserInput(e)} onChange={e => handleUserInput(e)} />
                <FormInput className={cx({ 'input__invalid': emailError })} type="email" name="email" placeholder="your email" isRequired={true} value={email} onBlur={e => handleUserInput(e)} onChange={e => handleUserInput(e)} />
                <FormInput className={cx({ 'input__invalid': messageError })} type="textarea" name="message" placeholder="your message" value={message} isRequired={true} onBlur={e => handleUserInput(e)} onChange={e => handleUserInput(e)} />
            </form>
            <div className="contactWrapper__submit">
                <div className="contactWrapper__submitCaptcha">
                    <span className="contactWrapper__submitCaptcha--text">Show me that humanity is still in you..<span className="contactWrapper__submitCaptcha--textIcon" role="img" aria-label="Smile icon">🙃</span></span>
                </div>
                <Recaptcha className={cx({ 'captcha__invalid': captchaError })} sitekey={siteKey} ref={captchaRef} verifyCallback={verifyCaptcha} expiredCallback={() => { setCaptchaError(true); setConfirmedCaptcha(false) }} />
                <ButtonIcon handleUserClick={submitForm} className="contactWrapper__submitButton" ariaLabel="Send message">
                    <span className="contactWrapper__submitButton--text">send</span>
                    <SendIcon className="contactWrapper__submitButton--icon" />
                </ButtonIcon>
            </div>


            {isModalOpened &&
                <>
                    <Helmet>
                        <body className="hideoverflow" />
                    </Helmet>
                    <Modal toggleModal={toggleModal}>
                        {loading ?
                            <LoaderIcon className="contactWrapper__modal--spinner" />
                            :
                            <>
                                <ButtonIcon type="submit" className="contactWrapper__modal--close" ariaLabel="Close modal" handleUserClick={toggleModal}>
                                    <CloseIcon className="contactWrapper__modal--closeIcon" />
                                </ButtonIcon>
                                <h3 className={emailResponses.heading.class}>{emailResponses.heading.text}</h3>
                                <p className="contactWrapper__modal--description">{emailResponses.message.text} {emailResponses.message.type === 'error' && <a href="mailto:kontakt@piotrkawula.pl" className="contactWrapper__header--paragraphLink">kontakt@piotrkawula.pl</a>}</p>
                                <span className={emailResponses.waterSign.class}>{emailResponses.waterSign.text}</span>
                            </>
                        }
                    </Modal>
                </>
            }
        </section>
    );
}

export default ContactForm;